<template>
  <v-container fluid>
    <v-row >
      <v-col cols="12">
        <h3>Mis beneficios</h3>
        <p>
          Consulta los beneficios que tienes como miembro / afiliado de la
          organización.
        </p>
      </v-col>
      <v-col
        :cols="
          $vuetify.breakpoint.smAndDown
            ? 12
            : $vuetify.breakpoint.lgAndDown
            ? 12
            : 4
        "
        v-for="(item, i) in benefitsList"
        :key="i"
      >
        <benefitsCard
          :name="item.name"
          :description="item.description"
          :icon="item.icon"
          :url="item.url"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import benefitsCard from '@/components/dashboard/Benefits/benefitsCard';
import { mapActions } from 'vuex/dist/vuex.common.js';
export default {
  components: {
    benefitsCard,
  },
  data() {
    return {
      benefitsList: [],
    };
  },
  created() {
    this.getAllBenefits();
  },
  methods: {
    ...mapActions('benefits', ['getUserBenefits']),
    async getAllBenefits() {
      try {
        let response = await this.getUserBenefits();
        this.benefitsList = response.data.benefits;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
