<template>
  <v-card
    elevation="0"
    class="d-flex justify-space-between pa-4 rounded-xxl"
    :class="validateOrganization ? 'flex-column' : 'align-center'"
    :color="color.primaryVariantClear"
  >
    <div class="d-flex align-center">
      <v-avatar :size="$vuetify.breakpoint.smAndDown ? 60 : 100">
        <v-img fit :src="icon" alt="benefit_image" />
      </v-avatar>
      <div class="overflow-y-auto mx-4">
        <h3 :class="!$vuetify.breakpoint.smAndDown || 'benefit_title-sm'">{{ name }}</h3>
        <p
          class="ma-0"
          :class="$vuetify.breakpoint.smAndDown ? 'benefit_description-sm' : 'benefit_description'"
          style="width: 100%; word-break: break-word"
        >
          {{ description }}
        </p>
      </div>
    </div>
    <div id="actions" class="d-flex justify-end" v-if="validateOrganization">
      <v-btn
        x-small
        @click="handleEdit()"
        elevation="0"
        class="white--text text-capitalize rounded-pill"
        fab
        :color="color.primaryVariant"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        x-small
        elevation="0"
        @click="handleDelete()"
        class="white--text text-capitalize rounded-pill ml-4"
        fab
        color="red"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </div>
    <div
      v-else-if="!validateOrganization && url"
      id="actions"
      class="d-flex justify-center"
    >
      <v-menu offset-y top open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-on="on"
            v-bind="attrs"
            elevation="0"
            fab
            x-small
            color="#35404c"
            class="white--text"
            @click="openLink(url)"
          >
            <v-icon> mdi-link-variant </v-icon></v-btn
          >
        </template>
        <v-sheet color="#fffaf8" class="pa-2" style="font-size: 14px; font-weight: 500">
          Adquiere tu beneficio!
        </v-sheet>
      </v-menu>
    </div>
  </v-card>
</template>
<script>
import { colors } from '@/constants/colors.js';
import { mapState } from 'vuex';

export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
    },
    description: {
      type: String,
      default: '',
    },
    expiredDate: {
      type: Date,
    },
    url: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      color: colors,
    };
  },
  methods: {
    handleEdit() {
      this.$emit('edit');
    },
    handleDelete() {
      this.$emit('delete');
    },
    openLink(url) {
      if (url) {
        window.open(url, '_blank');
      }
    },
  },
  computed: {
    ...mapState('user', ['user']),
    validateOrganization() {
      return ['HEAD_OFFICE', 'BRANCH', 'ORGANIZATION'].includes(this.user.role);
    },
  },
};
</script>
<style scoped>
.benefit_title-sm {
  font-size: 13px;
}
.benefit_description {
  font-size: 14px;
}
.benefit_description-sm {
  font-size: 11px;
}

.format_paragraph {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
